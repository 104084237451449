/* to target the search icon */
.ats-autocomplete .wrapper:first-child svg {
  /* border: 4px solid blue !important; */
  display: none !important;
}
.ats-autocomplete .wrapper:first-child > div {
  display: unset !important;
}

.ats-autocomplete .wrapper:last-child div > ul {
  border: 1px solid #cdcdcd;
}
.ats-autocomplete .wrapper,
.ats-autocomplete .wrapper:focus-within {
  border: none !important;
  box-shadow: none !important;
  z-index: 1 !important;
}

.ats-autocomplete .wrapper > div > input {
  width: 100% !important;
  min-width: 0px !important;
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
  position: relative !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -ms-appearance: none !important;
  appearance: none !important;
  transition-property: var(--chakra-transition-property-common) !important;
  transition-duration: var(--chakra-transition-duration-normal) !important;
  font-size: var(--chakra-fontSizes-md) !important;
  -webkit-padding-start: var(--chakra-space-4) !important;
  padding-inline-start: var(--chakra-space-4) !important;
  -webkit-padding-end: var(--chakra-space-4) !important;
  padding-inline-end: var(--chakra-space-4) !important;
  height: var(--chakra-sizes-10) !important;
  border-radius: var(--chakra-radii-md) !important;
  border: 1px solid !important;
  border-color: inherit !important;
  background: inherit !important;
}

.ats-autocomplete .wrapper > div > input[aria-invalid='true'] {
  border-color: #e53e3e;
  box-shadow: 0 0 0 1px #e53e3e;
}

.ats-autocomplete .wrapper > div > input:focus {
  z-index: 1;
  border-color: #3182ce;
  box-shadow: 0 0 0 1px #3182ce;
}
.ats-autocomplete .wrapper:focus {
  z-index: 1 !important;
  border-color: #3182ce !important;
  box-shadow: 0 0 0 1px #3182ce !important;
}

/* to target the input element */
.search .wrapper:first-child input {
  border: 4px solid red !important;
}

/* to target the wrapper element */
.search .wrapper:first-child {
  border: 4px solid;
}

.option-container {
  width: 100%;
  position: absolute;
  z-index: 10 !important;
  max-height: 300px;
  height: auto;
  background: white;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #ebebeb;
  margin-top: 2px;
  box-shadow: 0 0 12px 0px #ebebeb;
}
.option-container .item:hover {
  background-color: #ebebeb;
  color: inherit !important;
  text-decoration: none;
}
.option-container .item {
  cursor: pointer;
  padding: 7px 10px;
  border-bottom: 1px solid #ebebeb;
  display: block;
  color: inherit !important;
  text-decoration: none;
  font-weight: inherit;
}
.option-container .item:last-child {
  border-bottom: 0px !important;
}
