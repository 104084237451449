.ats-datepicker {
  width: 100% !important;
  min-width: 0px !important;
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
  position: relative !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -ms-appearance: none !important;
  appearance: none !important;
  transition-property: var(--chakra-transition-property-common) !important;
  transition-duration: var(--chakra-transition-duration-normal) !important;
  font-size: var(--chakra-fontSizes-md) !important;
  -webkit-padding-start: var(--chakra-space-4) !important;
  padding-inline-start: var(--chakra-space-4) !important;
  -webkit-padding-end: var(--chakra-space-4) !important;
  padding-inline-end: var(--chakra-space-4) !important;
  /* height: var(--chakra-sizes-10) !important; */
  border-radius: var(--chakra-radii-md) !important;
  /* border: 1px solid !important; */
  /* border-color: inherit !important; */
  background: inherit !important;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  height: 37px;
}
.ats-datepicker:focus {
  z-index: 1 !important;
  border-color: #3182ce !important;
  box-shadow: 0 0 0 1px #3182ce !important;
}
.ats-datepicker:hover {
  border-color: var(--chakra-colors-gray-300);
}
.react-datepicker__input-container .react-datepicker__calendar-icon {
  right: 0 !important;
  top: 1px !important;
}
.react-datepicker-wrapper {
  width: 100% !important;
  /* background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  height: 37px; */
}
