.filter-button {
  cursor: pointer;
  color: '#2B2D42';
  background-color: #fff;
}
.filter-button:hover {
  background: var(--chakra-colors-gray-100);
  background-color: var(--chakra-colors-primary-100);
}
.filter-button:active,
.filter-button:focus,
.filter-button:visited,
.filter-button.active {
  background: var(--chakra-colors-gray-100);
  background-color: #a475f4;
  border-color: var(--chakra-colors-primary-800);
  color: var(--chakra-colors-default-white-100);
  border-radius: var(--chakra-radii-md);
}
.filter-button.active > span,
.filter-button.active > span > button {
  background-color: #a475f4;
  color: var(--chakra-colors-default-white-100);
}
